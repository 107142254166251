<template>
	<v-app>
		
		<Toolbar />

		<v-main>
			<slot />
		</v-main>

		<Footer />
		<v-snackbar
			shaped
			top
			centered
			:timeout="alert.timeout"
			:color="alert.color"
			v-model="alert.show">
				{{ alert.message }}
		</v-snackbar>

	</v-app>
</template>

<script>
	import { mapState } from 'vuex';
	import Footer from '@/components/core/Footer.vue'
	import Toolbar from '@/components/core/Toolbar.vue'
	export default {
		name: 'LayoutAdmin',
		components: {
			Toolbar,
			Footer
		},
		data: () => ({
			
		}),
		mounted(){

		},
		computed: {
			...mapState(['alert']),
			isHome() {
				return this.$route.path === "/"
			},

			isAdmin() {
				return this.$store.state.userModule.user && this.$store.state.userModule.user.role == 'STAFF'
			},
			isUser(){
				return this.$store.state.userModule.user && 
							this.$store.state.userModule.user.role == 'USER' ||
							this.$store.state.userModule.user.role == 'MEMBER'
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>