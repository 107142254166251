<template>
  <v-sheet
    height="100vh"
    tile
  >
    <v-img
      :src="require('@/assets/login.jpg')"
      height="100%"
    >
      <v-container
        fill-height
        white--text
      >
        <v-slide-x-transition appear>
          <v-col
            align="center"
            justify="center"
          >
						<v-expand-transition>
							<v-card
								class="mx-auto my-12 transition-fast-in-fast-out v-card--reveal"
								max-width="374"
								elevation="20"
							>
								<v-img
									height="150"
									:src="require('@/assets/logo.png')"
									contain
								></v-img>

								<v-card-text>
									<h3 class="text-center mlt-4 primary--text">INTRANET SVDMQE</h3>

									<div class="mx-5">
										<v-form ref="form" v-model="validity">
											<v-text-field
												label="Email"
												v-model="email"
												prepend-icon="mdi-email"
												type="text"
												color="primary accent-3"
												:rules="[rules.required, rules.email]"
											/>
											<v-text-field
												id="password"
												label="Password"
												v-model="password"
												prepend-icon="mdi-lock"
												type="password"
												color="primary accent-3"
												:rules="[rules.required]"
											/>
										</v-form>
									</div>

								</v-card-text>

								<v-card-actions>
									<v-spacer/>
									<v-btn
										color="primary darken-2"
										:disabled="!validity"
										:loading="loading"
										@click="login"
									>
										INGRESAR
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-expand-transition>

          </v-col>
        </v-slide-x-transition>
      </v-container>
    </v-img>
  </v-sheet>
</template>

<script>
	import { mapState } from 'vuex';
	import axios from "axios";
	import store from '@/store';
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
  export default {
    name: 'PagesLogin',

    components: {
    },

    data: () => ({
			validity: false,
			loading: false,
      email: '',
			password: '',
			rules: {
				required: value => !!value || 'Required.',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalido email.'
				},
				password: value => value.length >= 6 || 'Contraseña obligatoria',
			},
    }),
		mounted(){
		},
		methods: {
			login(){

				const me = this;
				me.email = me.email.trim().toLowerCase()
				me.password = me.password.trim()
				me.loadingLogin = true
				axios.post('user/login',{
				  email: me.email, password: me.password
				})
					.then( response => { return response.data })
					.then(data =>{
						me.$store.dispatch("userModule/saveToken",data.tokenReturn);

						me.$store.dispatch("userModule/saveData",{
							"nombre": data.user.nombre,
							"apellido": data.user.apellido,
							"categoria": data.user.categoria,
							"miembro": data.user.miembro,
							"email": me.email
						});

						me.loadingLogin = false

            if (data.user.role === 'STAFF') {
              me.$router.push({name: 'Dashboard'});
            } else {
							// logout el usuario
              this.$store.dispatch("userModule/loggedOut",{root: true});
            }
          })
					.catch( function(error){
						let errorM = null
						if (error.response.status==404){
								errorM='No existe el usuario o las credenciales son incorrectas.';
						} else{
								errorM='Ocurrió un error con el servidor.';
						}

						me.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'error',
							message: errorM,
							timeout: 4000
						}, {root: true})

						me.loadingLogin = false

					});

			},
      reserve () {
        this.loading = true

        setTimeout(() => (this.loading = false), 2000)
      },
    },
  }
</script>
