<template>
  <v-footer  padless color="secondary" dark>
    <v-col
      class="text-center"
      cols="12"
    >
       Eventos <strong>SVDERMA.org</strong>. &copy; {{ anno }}. Caracas, Venezuela
    </v-col>
  </v-footer>
</template>
<script>
export default {
	name: 'Footer',
	computed: {
		anno() {
			return new Date().getFullYear();
		}
	}
}
	
</script>
