import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const theme = {
  primary: "#0097DF",
	secondary: "#0057D3",
	accent: "#BDBDBD",
	info: "#00BFA5",
	error: "#FF5252",
	success: "#4CAF50",
	warning: "#FFC107",
}



export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
