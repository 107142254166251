<template>
	<div>

		<v-container fluid>
			<v-row>
				<v-col>
					<v-app-bar
						dark 
						color="secondary"
						app 
						flat
						dense
						clipped-left
					>
			
							<v-app-bar-nav-icon 
								color="white"  
								@click.stop="drawer = !drawer" 
							/>
			
						
						<v-toolbar-title>INTRANET</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-avatar color="white" size="45">
							<v-img
								height="38"
								:src="require('@/assets/logo-azul-clip.png')"
								contain
							></v-img>
						</v-avatar>
			
					</v-app-bar>
				</v-col>
			</v-row>
		</v-container>


			<v-container fluid class="grey lighten-2">
				<v-row >
					<v-col cols="12" >
						<v-app-bar flat dense class="grey lighten-2">
						<v-breadcrumbs :items="breadItems"  class="mt-4">
							<template v-slot:divider>
								<v-icon>mdi-chevron-right</v-icon>
							</template>
						</v-breadcrumbs>
						</v-app-bar>
					</v-col>
				</v-row>
			</v-container>


		<drawer-component :drawer="drawer"/>

	</div>
</template>

<script>
	import store from '@/store';
	import DrawerComponent from '@/components/core/Drawer.vue'
	
	export default {
  components: { DrawerComponent  },
		name: 'Toolbar',
			data: () => ({
				infoUser: {
					nombre: '',
					apellido: '',
					categoria: '',
					miembro: ''
				},
				drawer:false,
				permanente: true,
				settings: [],
				items: [
					{ icon: 'mdi-view-dashboard', text: 'Inicio', to: 'Dashboard' },
					{ divider: true },
				],
				
			}),
			mounted(){
				this.infoUser = JSON.parse(localStorage.getItem("info"));
			},
			methods: {
				updateDrawer(value){
					this.drawer = value
				},
			},
			computed: {
				breadItems(){
					return this.$store.getters.getBread
				}
			}
			
		
	}
</script>

<style lang="scss" scoped>

</style>