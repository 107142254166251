import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import vuetify from './plugins/vuetify'
import VueMask from "v-mask";
import DefaultLayout from "./layouts/Default.vue";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import VuetifyConfirm from "vuetify-confirm";
Vue.use(VuetifyConfirm, { vuetify });

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})


Vue.use(VueMask);

Vue.config.productionTip = false

Vue.component("Layout", DefaultLayout);

axios.defaults.baseURL = `${process.env.VUE_APP_BASE_URL}/api/`

import VueQRCodeComponent from "vue-qr-generator";
Vue.component("qr-code", VueQRCodeComponent);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
